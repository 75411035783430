const ImagePlaceholder = () => import("@/components/image-placeholder");
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import GenericModal from "@/components/genericModal";
import {EventBus} from "@/main.js";
import axios from "@/axios/osint.js";
export default {
    name: "neo-dv-card",
    components: {
        ImagePlaceholder,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-generic-modal": GenericModal,
    },
    props: {
        thumbnails: {
            type: Array,
            default: null,
        },
        showImagesModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            current: 0,
            direction: 1,
            transitionName: "fade",
            show: false,
            isHeader: true,
        };
    },
    methods: {
        slide(dir) {
            this.direction = dir;
            dir === 1 ? (this.transitionName = "slide-next") : (this.transitionName = "slide-prev");
            // var len = this.thumbnails.length;
            this.current = this.current + dir;
        },
        async beforeOpen() {
            this.loading = true;
            this.loading = false;
        },
        closeModal() {
            EventBus.$emit("showModal", false);
            // this.$modal.hide('carousel-effect')
            this.showImagesModal = false;
        },
        async getURL(url, id) {
            const api = `instagram/image/${btoa(url)}?format=url`;
            let image = "";
            image = await axios.get(api);
            if (image?.data?.data && id && document.getElementById(id) && document.getElementById(id).src) document.getElementById(id).src = image.data.data;
        },
    },

    mounted() {
        this.show = true;
    },
};
