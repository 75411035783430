import {uniq} from "lodash";
export default {
    name: "neo-dv-card",
    components: {},
    props: {
        card: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        identifierInputs() {
            let data = [];
            for (let index in this.card.identifier_inputs) {
                data = data.concat(Object.values(this.card.identifier_inputs[index]));
            }
            return uniq(data.filter((el) => el));
        },
        matchingIdentifier() {
            let data = [];
            for (let index in this.card.identifiers) {
                delete this.card.identifiers[index]?.platform;
                data = data.concat(Object.values(this.card.identifiers[index]));
            }
            return uniq(data.filter((el) => el));
        },
        identifierTools() {
            let data = [];
            for (let index in this.card.identified_source) {
                data = data.concat(Object.values(this.card.identified_source[index]));
            }
            return uniq(data.filter((el) => el));
        },
    },
    mounted() {},
    methods: {
        async beforeOpen() {
            this.loading = true;
            this.loading = false;
        },
        hideModal() {
            this.$emit("close");
            this.$modal.hide(`case-identifiers-${this.card._id}`);
        },
    },
};
