import {mapGetters} from "vuex";
import {getPlatformName, getPlatformURL, getPlatformRedirectURL, getSocialProfileIcon} from "@/utils/functions";
import NeoAnalyse from "@/components/analyse";
import Badge from "@/components/badge";
import CardNotes from "@/containers/card-notes";
import caseIdentifiers from "./case-identifiers";
import {cloneDeep, uniq} from "lodash";
import moreInfo from "./more-info/index.vue";
import imageList from "./image-list";
const ImagePlaceholder = () => import("@/components/image-placeholder");
import {EventBus} from "@/main.js";
import axios from "@/axios/osint.js";

export default {
    name: "neo-dv-card",
    components: {
        "neo-analyse": NeoAnalyse,
        "neo-badge": Badge,
        "neo-card-notes": CardNotes,
        caseIdentifiers,
        ImagePlaceholder,
        moreInfo,
        imageList,
    },
    props: {
        card: {
            type: Object,
            default: null,
        },
        tab: {
            type: String,
            default: "",
        },
        showNotes: {
            type: Boolean,
            default: true,
        },
        listView: {
            type: Boolean,
            default: false,
        },
        showImagesModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showStatusList: false,
            statusOptions: [
                {
                    label: "Confirmed",
                    value: "confirmed",
                    color: "#67B52B",
                    bg: "green",
                    icon: "check_solid",
                },
                {
                    label: "Potential",
                    value: "potential",
                    color: "#EB3131",
                    bg: "red",
                    icon: "question_solid",
                },
                {
                    label: "Irrelevant",
                    value: "irrelevant",
                    color: "rgba(107, 114, 128, 1)",
                    bg: "gray",
                    icon: "xmark",
                },
                {
                    label: "Unknown",
                    value: null,
                    color: "rgba(107, 114, 128, 1)",
                    bg: "gray",
                    icon: "minus",
                },
            ],
            urlContent: false,
            activeCard: null,
            cardThumbnails: [
                // {
                //     "url": "https://lh3.googleusercontent.com/cm/AJSPFBzoNLuf0oi48EGq37meGQtYDkPhBGmKQIqmqIHJ5I2gXWqC0mKSTK86VhGlRN9X=s1600"
                // },
                // {
                //     "url": "https://lh3.googleusercontent.com/a-/AD_cMMQItQpsCjcmVlCgSx5TPb8vgV_8y6PWxqKGOgIGTh-l=s1600"
                // },
                // {
                //     "url": "https://lh3.googleusercontent.com/c5dqxl-2uHZ82ah9p7yxrVF1ZssrJNSV_15Nu0TUZwzCWqmtoLxCUJgEzLGtxsrJ6-v6R6rKU_-FYm881TTiMCJ_=s1600"
                // }
            ],
            showthumbnails: false,
            isMoreInfo: false,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getEntitiesRelationsAttributesData"]),
    },
    created() {
        EventBus.$on("showModal", (data) => {
            this.showImagesModal = data;
        });
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getEntitiesRelationsAttributesData"]),
        getCategoriesList() {
            return this.$store.getters.getRiskCategories;
        },

        isMoreInfo() {
            return ["keyFacts", "socialProfiles", "associatedPeople", "media"].includes(this.tab);
        },

        confidenceScore() {
            let color, bg;
            if (this.card.confidence_score?.name?.toLowerCase() === "high") {
                bg = "rgba(209, 250, 229, 1)";
                color = "#67B52B";
            } else if (this.card.confidence_score?.name?.toLowerCase() === "medium") {
                bg = "rgb(255 219 181)";
                color = "rgb(249 149 0)";
            } else if (this.card.confidence_score?.name?.toLowerCase() === "low") {
                bg = "rgb(255, 246, 222)";
                color = "rgb(209, 157, 0)";
            } else {
                bg = "rgba(243, 244, 246, 1)";
                color = "#0D69D5";
            }

            return {color, bg};
        },

        cardStatus() {
            let icon, color, bg;
            if (this.card.status === "CONFIRMED") {
                icon = "check_solid";
                bg = "#F0F8EA";
                color = "#67B52B";
            } else if (this.card.status === "POTENTIAL") {
                icon = "question_solid";
                bg = "#FBEDED";
                color = "#EB3131";
            } else if (this.card.status === "IRRELEVANT") {
                icon = "xmark";
                bg = "rgba(243, 244, 246, 1)";
                color = "rgba(107, 114, 128, 1)";
            } else {
                icon = "minus";
                bg = "rgba(243, 244, 246, 1)";
                color = "rgba(107, 114, 128, 1)";
            }

            return {icon, color, bg};
        },

        matchingIdentifier() {
            let data = [];
            for (let index in this.card.identifiers) {
                delete this.card.identifiers[index]?.platform;
                data = data.concat(Object.values(this.card.identifiers[index]));
            }
            return uniq(data.filter((el) => el));
        },

        checkScreenshot() {
            if (this.card.screenshot) {
                if (!this.card.screenshot.available) return false;
                else if (this.card.screenshot.location == "") return false;
                else return true;
            }
            return false;
        },

        mediaType() {
            if (!this.card.media_type) {
            }
            if (this.card.media_type?.toLowerCase() == "passive_media") {
                let media = this.card.media_type?.toLowerCase().split("_")[1];
                return media.charAt(0).toUpperCase() + media.slice(1);
            }
            return this.card.media_type
                ?.toLowerCase()
                ?.split("_")
                ?.map(function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                ?.join(" ");
        },
        mediaPublishedDate() {
            let publishedDt = this.card?.published_at?.split("T")[0].split("-");
            if (publishedDt[1] == "") return publishedDt[0];
            else if (publishedDt[2] == "") return publishedDt[0] + "-" + publishedDt[1];
            return this.card?.published_at?.split("T")[0];
        },

        urlSource() {
            let url;
            try {
                url = new URL(this.card.url);
            } catch (error) {}

            let res = this.card?.url?.match(/^(ftp|http|https):\/\/[^ "]+$/);
            if (res) {
                this.urlContent = true;
            } else this.urlContent = false;
            if (url) {
                if ((url.hostname == "www.google.com" || url.hostname == "www.google.co.uk" || url.hostname == "www.google.co.in") && url.pathname == "/search") {
                    return "Google Search: " + url.searchParams.get("q");
                }
            }

            return this.card.url;
        },

        risks() {
            if (!this.card.all_risk_categories) {
                return [];
            }
            return this.card.all_risk_categories.map((tag) => tag);
        },

        topics() {
            if (!this.card.categories) {
                return [];
            }
            return this.card.categories.map((tag) => {
                if (tag.labels && tag.labels.length) return tag.labels[tag.labels.length - 1];
            });
        },

        key_facts() {
            if (!this.card.key_facts) {
                return [];
            }
            return this.card.key_facts.map((ele) => ({
                tag: ele.key_fact_name,
            }));
        },
    },
    mounted() {},
    methods: {
        getPlatformName,
        getPlatformURL,
        getPlatformRedirectURL,
        getSocialProfileIcon,

        getEntityIcon(identifier) {
            let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == identifier);
            if (icondata.length > 0) return icondata[0].icon_data;
            else return require("@/assets/icons/documents.svg");
        },

        getTagColor(tag) {
            const groupName = this.getCategoriesList.find((catList) => catList.categories.some((elem) => elem.category === tag));
            if (groupName?.group === "Risk Categories") {
                if (this.card.entity_status && this.card.entity_status.toLowerCase() === "amber") {
                    return {background: "#FFF6DE", textColor: "#D19D00", iconBg: "#FFDC86"};
                } else {
                    return {background: "var(--color-danger-bg)", textColor: "var(--color-danger)", iconBg: "#faccd4"};
                }
            } else if (groupName?.group === "Non-Risk Categories") {
                return {background: "rgb(220 252 231)", textColor: "rgb(21 128 61)", iconBg: "rgb(134 239 172)"};
            } else {
                return {};
            }
        },

        closeStatusList() {
            this.showStatusList = false;
        },
        toggleEdit() {
            const eid = this.card?.entity_type?._id;
            const item = this.getEntitiesRelationsAttributesData.entities.find((e) => e._id == eid);
            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                all_data: this.card,
                editEntity: true,
                entity: item,
                text: "",
                userDefined: true,
            });

            this.showMoreInfo();
            // this.$store.dispatch("showAddEntityPanel");
        },
        async getURL(url, id) {
            let api = `instagram/image/${btoa(url)}?format=url`;
            let image = "";
            image = await axios.get(api);
            if (image?.data?.data && id && document.getElementById(id) && document.getElementById(id).src) document.getElementById(id).src = image.data.data;
        },
        async getURL(url, id) {
            let api = `instagram/image/${btoa(url)}?format=url`;
            let image = "";
            image = await axios.get(api);
            if (image?.data?.data && id && document.getElementById(id) && document.getElementById(id).src) document.getElementById(id).src = image.data.data;
        },

        editSocialProfile() {
            let item = JSON.parse(JSON.stringify(this.card));
            this.card.relations = this.card?.relations?.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: "social_profile",
                all_data: this.card,
                entity: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });
            this.showMoreInfo();
            // this.$store.dispatch("showAddEntityPanel");
        },

        editAssociatedEntity() {
            const eid = this.card?.entity_type?._id;
            const item = this.getEntitiesRelationsAttributesData.entities.find((e) => e._id == eid);
            this.card.relations = this.card?.relations?.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                all_data: this.card,
                entity: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.showMoreInfo();
            // this.$store.dispatch("showAddEntityPanel");
        },

        editMedia() {
            let item = JSON.parse(JSON.stringify(this.card));
            this.card.relations = this.card?.relations?.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: this.card.media_type,
                all_data: this.card,
                entity: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.showMoreInfo();
            // this.$store.dispatch("showAddEntityPanel");
        },

        changeStatus(option) {
            let card = cloneDeep(this.card);
            const status = option.value?.toUpperCase() || null;
            // if (this.tab === "socialProfiles") {
            //     if (status !== "IRRELEVANT") {
            //         let screenshotRequired = this.$store.getters.getSocialPlatforms.find((el) => el.key === this.card.platform)?.require_screenshot_in_report ?? false;
            //         if (screenshotRequired) {
            //             if (!this.checkScreenshot) {
            //                 this.$toast.error("Screenshot required");
            //             }
            //         }
            //     }
            // }
            delete card.confidence_score;
            this.$emit("dataChanges", {
                card: {
                    ...card,
                    status,
                },
            });
            this.showStatusList = false;
        },

        toggleStatusList() {
            this.showStatusList = !this.showStatusList;
        },

        editAndMoreInfo() {
            if (this.tab === "associatedPeople") this.editAssociatedEntity();
            else if (this.tab === "socialProfiles") {
                this.$store.dispatch("matchingProfile", {
                    username: this.card.username,
                    platform: this.card.platform,
                    notes: this.card.notes ? this.card.notes : "",
                    customer_notes: this.card.customer_notes ? this.card.customer_notes : "",
                });
                this.editSocialProfile();
            } else if (this.tab === "media") this.editMedia();
            else this.toggleEdit();
        },

        openCaseIdentifiers(card) {
            this.activeCard = card._id;
            this.$modal.show(`case-identifiers-${this.activeCard}`);
        },

        showMoreInfo() {
            this.isMoreInfo = true;
        },

        async dataChanges(card) {
            await this.$emit("dataChanges", {
                card,
                ref: this,
            });
        },

        closeMoreInfo() {
            this.isMoreInfo = false;
        },

        showImages(thumbnails) {
            this.showImagesModal = true;
            this.cardThumbnails = thumbnails;
        },
    },
};
